import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { FaTwitter, FaSteam, FaYoutube, FaEnvelope } from 'react-icons/fa';

function Navbar() {
  return (
    <nav className="navbar">
      <div className="center-links">
        <Link to="/" className="navbar-link">HOME</Link>
        <Link to="/contact" className="navbar-link">CONTACT</Link>
        <Link to="/press-kit" className="navbar-link">PRESS</Link>
      </div>

      <div className="social-links">
        <a href="mailto:voidmaw@protonmail.com" className="social-icon">
          <FaEnvelope size={20} />
        </a>
        {/* <a href="https://discord.gg/52qE2JF2FX" target="_blank" rel="noopener noreferrer" className="social-icon">
          <FaDiscord size={20} />
        </a>         */}
        <a href="https://store.steampowered.com/app/3032830/Katanaut/" target="_blank" rel="noopener noreferrer" className="social-icon">
          <FaSteam size={20} />
        </a>
        <a href="https://x.com/VoidmawGames" target="_blank" rel="noopener noreferrer" className="social-icon">
          <FaTwitter size={20} />
        </a>
        <a href="https://www.youtube.com/watch?v=5V3ArIF-uUk" target="_blank" rel="noopener noreferrer" className="social-icon">
          <FaYoutube size={20} />
        </a>
        {/* <a href="https://www.tiktok.com/@katanaut_" target="_blank" rel="noopener noreferrer" className="social-icon">
          <FaTiktok size={20} />
        </a> */}
      </div>
    </nav>
  );
}

export default Navbar;
