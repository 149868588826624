import React from 'react';
import './PressKit.css';
import Navbar from './Navbar';

function PressKit() {
  return (
    <div className="press-kit-page">
      <Navbar />
      <h1 className="press-kit-title">Press Kit</h1>
      <div className="press-kit-container">
        <div className="fact-sheet">
          <h2 className="highlight">Fact Sheet</h2>
          <p>
            <span className="sub-highlight">Official Game Title:</span><br />
            <b>Katanaut</b><br /><br />

            <span className="sub-highlight">Developer:</span><br />
            Voidmaw<br />
            Based in United States<br /><br />

            <span className="sub-highlight">Release Date/Window:</span><br />
            Early 2025<br /><br />

            <span className="sub-highlight">Genre:</span><br />
            Action-Roguelite, Dark, 2D Pixel Art<br /><br />

            <span className="sub-highlight">Platforms:</span><br />
            PC (Steam)<br /><br />

            <span className="sub-highlight">Number of Players:</span><br />
            Single Player<br /><br />

            <span className="sub-highlight">Release Regions:</span><br />
            Worldwide<br /><br />

            <span className="sub-highlight">Marketing Tagline:</span><br />
            "The stars reject us"<br /><br />

            <span className="sub-highlight">ESRB with Descriptors:</span><br />
            Rating Pending (Expected rating: Mature)<br /><br />

            <span className="sub-highlight">Languages:</span><br />
            English, French, Spanish, German, Italian, Portuguese, Russian, Turkish, Dutch, Polish, Japanese, Simplified Chinese, Traditional Chinese, Korean<br /><br />

            <span className="sub-highlight">Website:</span><br />
            <a href="https://www.katanaut.com" target="_blank" rel="noopener noreferrer" className="deep-red-link">katanaut.com</a><br /><br />
          </p>
        </div>

        <div className="press-kit-content">
          <div className="press-kit-link-container">
            <a 
              href="https://drive.google.com/drive/folders/111-hxsGUPewYIcA-XlxBdw1mE4CqQ8am?usp=sharing" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="press-kit-link"
            >
              Download Press Kit Assets
            </a>
          </div>

          <h2 className="highlight">Elevator Pitch</h2>
          <p>
            <b>Katanaut</b> is an action-roguelite set aboard a colossal space station plagued by mysterious horrors. Players must adapt to each run by collecting loot, upgrading skills, and unlocking powerful weapons, perks, and abilities. Fight through procedurally generated levels, each filled with relentless enemies and secrets waiting to be uncovered.
          </p>

          <h2 className="highlight">Description</h2>
          <p>
            <b>Katanaut</b> plunges players into the ruins of a massive space station after a catastrophic event transforms its inhabitants into monstrous creatures. With every run, players collect currency to upgrade or unlock new skills, weapons, and passive perks that shape their playstyle. Combat is fast and tactical, requiring precise movement and mastery of an evolving arsenal. Randomized environments, challenging encounters, and hidden relics ensure no two runs are the same, pushing players to adapt and explore deeper into the station’s eerie corridors.
          </p>

          <h2 className="highlight">Features</h2>
          <ul>
            <li>
              <b style={{ color: '#d0be54' }}>High-Stakes Combat:</b> Reflexes and strategy are vital to overcoming relentless foes.
            </li>
            <li>
              <b style={{ color: '#d0be54' }}>Endless Variety:</b> Randomized levels, enemies, and loot ensure a fresh challenge with every run.
            </li>
            <li>
              <b style={{ color: '#d0be54' }}>Unlock and Upgrade:</b> Earn currency to purchase or enhance skills, weapons, and passive perks that shape your strategy.
            </li>
            <li>
              <b style={{ color: '#d0be54' }}>Exploration and Discovery:</b> Unearth hidden areas and ancient relics that offer unique rewards.
            </li>
            <li>
              <b style={{ color: '#d0be54' }}>Challenging Boss Fights:</b> Face terrifying creatures with mechanics that demand mastery and skill.
            </li>
            <li>
              <b style={{ color: '#d0be54' }}>Endless Replayability:</b> Adapt and learn as each run presents new challenges and opportunities for growth.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PressKit;
