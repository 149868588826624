import React, { useState, useEffect } from 'react';
import './App.css';
import Navbar from './Navbar';
import llclogo from './images/voidmawlogo.png';
import steamlogo from './images/logos/steam.webp';
import logo from './images/logo.png';
import headerImage from './images/header.jpg';
import video1 from './videos/floorslide.mp4';
import video2 from './videos/floorslide.webm';
import video3 from './videos/hound.mp4';
import video4 from './videos/hound.webm';

function Home() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', checkScreenSize);
    checkScreenSize();
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const scrollToVideo = () => {
    const videoElement = document.querySelector('.header-video-container');
    if (videoElement) {
      const navbarHeight = document.querySelector('.navbar').offsetHeight;
      const videoPosition = videoElement.getBoundingClientRect().top + window.scrollY - navbarHeight - 20; // 20px padding
      window.scrollTo({ top: videoPosition, behavior: 'smooth' });
    }
  };

  const openSteamPage = () => {
    window.open('https://store.steampowered.com/app/3032830/Katanaut/', '_blank');
  };

  const scrollToContent = () => {
    const contentElement = document.querySelector('.App-content');
    if (contentElement) {
      const navbarHeight = document.querySelector('.navbar').offsetHeight;
      const contentPosition = contentElement.getBoundingClientRect().top + window.scrollY - navbarHeight - 20; // 20px padding
      window.scrollTo({ top: contentPosition, behavior: 'smooth' });
    }
  };

  const handleVideoClick = (event) => {
    const videoElement = event.target;
    if (videoElement.requestFullscreen) {
      videoElement.requestFullscreen();
    } else if (videoElement.mozRequestFullScreen) { // Firefox
      videoElement.mozRequestFullScreen();
    } else if (videoElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
      videoElement.webkitRequestFullscreen();
    } else if (videoElement.msRequestFullscreen) { // IE/Edge
      videoElement.msRequestFullscreen();
    }
  };

  const handleVideoError = (e) => {
    console.error('Video failed to load', e);
  };

  return (
    <div className="App">
      <Navbar />
      <div className="header-container">
        <img src={headerImage} alt="Header" className="header-image" />
        <div className="logo-and-buttons">
          <img src={logo} alt="Katanaut Logo" className="header-logo" />
          <div className="button-container">
            <button className="wishlist-button" onClick={openSteamPage}>
              WISHLIST
            </button>
            <button className="trailer-button" onClick={scrollToVideo}>
              TRAILER
            </button>
          </div>
          <div className="about-container">
            <button className="about-button" onClick={scrollToContent}>ABOUT</button>
          </div>
          <div className="steam-logo-container" onClick={openSteamPage}>
            <img src={steamlogo} alt="Steam Logo" className="steam-logo" />
            <span className="release-date">Q2 2024</span>
          </div>
        </div>
      </div>

      <div className={`header-video-container ${isMobile ? 'mobile-header' : ''}`}>
        <iframe
          className="header-video"
          src="https://www.youtube.com/embed/5V3ArIF-uUk"
          title="Katanaut Trailer"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div className="App-content">
        <h1 className="main-title">What is Katanaut?</h1>
        <div className="description-container">
          <p>
            <b>Katanaut</b> is a relentless action-roguelite set aboard a space station spiraling into cosmic chaos. You are the shattered spirit of a lost warrior, bound to a katana forged to pierce the fabric of reality. Fight through an ever-changing world, master your blade, and uncover the truth behind the destruction around you.
          </p>
          <p>
            <b>Die, adapt, and return.</b> Each defeat shatters your form, sending you back to the void to reform and try again. With every return, the station shifts—and so do you. No checkpoints. Every battle pushes your skill, focus, and determination to their limits.
          </p>
          <p>
            <b>Master fast and intense combat.</b> Combine precise melee strikes with devastating ranged attacks, unlocking powerful abilities and enhancements to craft unique builds each run. Face horrifying creatures, deadly bosses, and escalating challenges as reality crumbles around you.
          </p>
          <p>
            <b>Cyberpunk meets cosmic horror.</b> Explore twisted corridors and eerie environments where cyberpunk technology collides with eldritch terror. Will you survive the collapse—or succumb to madness?
          </p>
          <p>
            <b>Unravel a fractured narrative.</b> Piece together cryptic clues hidden in your fragmented memories. Face haunting echoes of your past and uncover the station’s darkest secrets. As the truth emerges, so does the terrifying cost of survival.
          </p>
        </div>

        <div className="feature-grid">
          <p><b style={{ color: '#d0be54' }}>High-Stakes Combat:</b> Every encounter matters. Reflexes and strategy are your greatest weapons.</p>
          <p><b style={{ color: '#d0be54' }}>Endless Variety:</b> Hand crafted levels mixed with procedural generation, enemies, and loot.</p>
          <p><b style={{ color: '#d0be54' }}>Unlock and Master:</b> Discover new weapons and abilities that shape your combat style.</p>
          <p><b style={{ color: '#d0be54' }}>Explore and Conquer:</b> Uncover hidden areas, secrets, and powerful relics to gain the edge.</p>
        </div>
        <div className="videos">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="video"
            onClick={handleVideoClick}
            onCanPlayThrough={(e) => e.target.play()}
            onError={handleVideoError}
          >
            <source src={video1} type="video/mp4" />
            <source src={video2} type="video/webm" />
          </video>
          <video
            autoPlay
            loop
            muted
            playsInline
            className="video"
            onClick={handleVideoClick}
            onCanPlayThrough={(e) => e.target.play()}
            onError={handleVideoError}
          >
            <source src={video3} type="video/mp4" />
            <source src={video4} type="video/webm" />
          </video>
        </div>
      </div>
      <div className="footer-logo-container">
        <img src={llclogo} alt="Voidmaw Logo" className="footer-logo" />
      </div>
    </div>
  );
}

export default Home;
